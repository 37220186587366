import React from 'react';
import CardDesc from '../atoms/cardDesc';
import CardHeader from '../atoms/cardHeader';

const OtherSouzokuCard = ({
    headerTitle, 
    headerSubTitle, 
    headerBgColor,
    descTitle,
    descText,
    descBorderColor,
    descTextColor,
    descTitleIcon,
    descList,
    listIcon,
    optionTitle,
    optionTitleIcon,
    optionList,
    optionListIcon
}) => {
  return (
    <section className='container mx-auto mt-4 px-4'>
        <div className='mx-auto my-6 rounded border border-gray-400 shadow-lg w-full'>
            <CardHeader title={headerTitle} subTitle={headerSubTitle} bgColor={headerBgColor}/>
            <CardDesc
                title={descTitle}
                text={descText}
                borderColor={descBorderColor}
                textColor={descTextColor}
                titleIcon={descTitleIcon}
                descList={descList}
                listIcon={listIcon}
                optionTitle={optionTitle}
                optionTitleIcon={optionTitleIcon}
                optionList={optionList}
                optionListIcon={optionListIcon}
            >
            </CardDesc>
        </div>
    </section>
  );
};

export default OtherSouzokuCard;
