import { motion } from 'framer-motion'
import React from 'react'

const MotionCard = ({cardTitle, children}) => {

    return (
        <motion.div initial={{opacity: 0}} whileInView={{y:[20, 0], opacity: 1}} transition={{ type: 'spring', duration: 0.8 }} viewport={{once: true, amount: 0.1}} className='mx-auto bg-gray-100 rounded my-6 p-4 shadow-lg w-11/12'>
            <h1 className='text-xl text-indigo-500 text-center font-extrabold border-b-2 border-lime-500 tracking-widest mb-4'>{cardTitle}</h1>
            <motion.div initial={{opacity: 0}} whileInView={{ opacity: [0.2, 0.5, 0.7, 0.9, 1]}} transition={{ type: 'spring', duration: 0.8}} viewport={{once: true, amount: 0.1}} className='text-pink-500 text-lg whitespace-pre-wrap leading-loose'>
                {children}
            </motion.div>
        </motion.div>
        
    )
}

export default MotionCard
