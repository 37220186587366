import React from 'react'
import MotionCard from '@/components/blocks/motionCard'
import Layout from '@/components/Layout'
import ArticleTemp from '@/components/modules/articleTemp'
import { faExclamationCircle, faLink, faStar } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import OtherSouzokuCard from '@/components/blocks/otherSouzokuCard'
import SEO from '@/components/seo'


const Method = () => {

    const data = useStaticQuery(graphql`
        query {
            allFile(
              filter: {sourceInstanceName: {eq: "souzokuTejyun"}}
              sort: {fields: childMdx___frontmatter___meidx, order: ASC}
            ) {
              nodes {
                childMdx {
                  frontmatter {
                    meidx
                    metitle
                    melink
                    melinkText
                  }
                  body
                }
              }
            }
          }`)

    return (
        <Layout>
            <SEO title={`相続登記の進め方`} description={`相続登記はどのように進めれば良いのか、何から始めたら良いのかをご紹介します。他に、相続手続きを進めるうえで、便利な法定相続情報証明制度もご参考ください。`} />
            <ArticleTemp header={"相続登記の進め方"} descText={"一般的な相続登記の進め方についてご紹介します。"} >
                <div className='container mx-auto'>
                    {data.allFile.nodes.map(node => (
                        <MotionCard cardTitle={node.childMdx.frontmatter.metitle} key={node.childMdx.frontmatter.meidx}>
                            <MDXRenderer>
                                {node.childMdx.body}
                            </MDXRenderer>
                            {(node.childMdx.frontmatter.melink && node.childMdx.frontmatter.melink) && (
                                <>
                                    <div className='text-right text-indigo-700 my-4'>
                                        <Link to={`${node.childMdx.frontmatter.melink}`} className='border-b border-indigo-700'>
                                            <FontAwesomeIcon icon={faLink} className='mr-2' />
                                                {node.childMdx.frontmatter.melinkText}
                                        </Link>
                                    </div>
                                </>
                            )}
                        </MotionCard>
                    ))}
                </div>
            </ArticleTemp>
            <ArticleTemp header={`その他の相続手続き`} descText={`不動産以外の相続について、法定相続情報の活用や、相続放棄の申立てについてご紹介します。`}>
                <OtherSouzokuCard 
                    headerTitle={`法定相続情報証明制度`}
                    headerSubTitle={`法務局保管の相続関係説明図`} 
                    headerBgColor={`bg-teal-400`}
                    descTitle={`法定相続情報一覧図の作成`}
                    descText={`法定相続情報証明制度は，法務局に相続人を確定させるために収集した戸籍等と一緒に，相続関係を一覧に表した図（法定相続情報一覧図）を提出することによって，法務局で認証文を付した相続関係の証明書を無料で発行してくれる制度です。これによって、この証明書１枚を提出することによって、複数の提出先に戸籍の束を出さずに済むようになります。現在では多くの金融機関等がこの証明書に対応してます。`}
                    descBorderColor={`border-teal-400`}
                    descTextColor={`text-teal-400`}
                    descTitleIcon={faThumbsUp}
                    descList={['相続関係の戸籍は１セット集めるだけで良い', '必要通数分を請求しても無料で発行できる', '足りなくなれば、再度発行してもらえる', '相続登記の他、相続税の申告、年金手続など多くの相続手続で利用できる', '不動産を所有していなくても利用できる']}
                    listIcon={faStar}
                    optionTitle={``}
                    optionTitleIcon={``}
                    optionList={[]}
                    optionListIcon={``}
                />
                <OtherSouzokuCard 
                    headerTitle={`相続放棄`}
                    headerSubTitle={`予期しない相続債務を負担することを避けるために`} 
                    headerBgColor={`bg-cyan-400`}
                    descTitle={`相続放棄`}
                    descText={`相続人となった事実を””知ったときから””3か月以内に行わなければなりません。ただし、３ヶ月を経過したと思われるようなケースであっても、相当な理由があるときなどは、””知ったとき””の要件を柔軟に解釈して相続放棄の申述が受理されることもあります。相続放棄の申述が受理されれば、相続放棄申述受理証明書を取得できるようになり、最初から相続人ではなかったことになります。`}
                    descBorderColor={`border-cyan-400`}
                    descTextColor={`text-cyan-400`}
                    descTitleIcon={faThumbsUp}
                    descList={['予期せぬ保証債務など一切の相続債務の負担を免れる', '相続争いに巻き込まれない', '原則、相続放棄の撤回はできない', '相続財産を使ってしまった場合など、一定の場合、相続放棄をすることができなくなる場合があります。']}
                    listIcon={faStar}
                    optionTitle={``}
                    optionTitleIcon={``}
                    optionList={[]}
                    optionListIcon={``}
                />
                 <section className='container mx-auto mt-4 px-4'>
                    <div className='mx-auto my-6 text-lg w-full'>
                        <p className='leading-relaxed'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='text-red-600 mr-2' />
                            その他、相続税の申告（１０ヶ月以内）、所得税の準確定申告（４ヶ月以内）など、相続手続きには期限が定められているものがありますので、ご注意ください。
                        </p>
                    </div>
                 </section>
                
            </ArticleTemp>
        </Layout>
    )
}

export default Method
